// import TextTemplate from "@/assets/icons/email-admin/icon-text-temp.svg";
import DropdownIcon from "@/assets/icons/email-admin/icon-dropdown.svg";
import EmailPlaceholder from "./../emailtemplatemodal/placeholders";
import { quillEditor, Quill } from "@shared/lib/vue-quill-editor";
import { ImageExtend } from "quill-image-extend-module";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
Quill.register("modules/ImageExtend", ImageExtend);
Quill.register(
  true
);
var Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block);

import { uniq } from "lodash";

export default {
  name: "custom-input",
  components: {
    DropdownIcon,
    EmailPlaceholder,
    quillEditor
  },
  data() {
    return {
      content: this.value,
      sendoptions: false,
      editorOptions: {
        debug: "info",
        placeholder: "Subject",
        readOnly: true,
        theme: "snow",
        modules: {
          toolbar: {
            container: [],
          },
          table: false,
        },
      },
    };
  },
  props: {
    msg: String,
    placeholder: String,
    type: String,
    icon: String,
    value: String,
    errorMessage: String,
    isError: Boolean,
    showDropDown: {
      type: Boolean,
      default: false
    },
    isQuill: {
      type: Boolean,
      default: false
    },
    labelWidth: String,
    customClass: String,
    formData: {
      type: Object
    },
  },
  updated(){
    console.log("input", this.formData.mode);
  },
  computed: {
    getInputStyle() {
      if (this.errorMessage && !this.value)
        return `width: calc(100% - ${this.labelWidth} + 32px)`;
      else return `width: calc(100% - ${this.labelWidth})`;
    }
  },
  methods: {
    replaceQuillPlaceholders(content) {
        let placeholders = content.match(/\{{(.*?)\}}/g);
        const quill = this.$refs.myQuillEditor?.quill;
        placeholders = uniq(placeholders);
        for (const index in placeholders) {
          if (quill) {
            const text = placeholders[index];
            let content = quill.getText();
            let sub_content = quill.getText();
            let start = 0;
            while (sub_content.indexOf(text) > -1) {
              start += sub_content.indexOf(text);
              const length = text?.length;
              this.formatText(quill, start, length);
              this.removeFormat(quill);
              start += length;
              sub_content = content.substr(start, content.length);
            }
          }
        }
    },
    insertTemplatePlaceholder(placeholder) {
      // const quill = this.$refs.myQuillEditor.$refs.editor.quill
      const quill = this.$refs.myQuillEditor.quill;
      if (quill) {
        var selection = quill.getSelection(true);
        const length = placeholder.placeholder?.length;
        quill.insertText(selection.index, `{{${placeholder.placeholder}}}`);
        // this.formatText(quill, selection.index, length+4);
        // this.removeFormat(quill);
        quill.setSelection(selection.index+length+4);
      }
    },
    formatText(quill, start, length) {
      quill.formatText(start, length, {
        color: 'rgb(13, 105, 213)',
        bold: true,
      });
    },
    removeFormat(quill) {
      quill.format('bold', false);
      quill.format('color', 'black');
    },
    handleInput(value) {
      if (this.showDropDown && this.sendoptions)
        this.sendoptions = false;
      this.$emit("input", value);
      this.$emit('removeError',false)
    },
    popupbtn() {
      this.sendoptions = !this.sendoptions;
    },
    onOutsideClick() {
      this.sendoptions = false;
    },
  },
};
