import DropdownIcon from "@/assets/icons/email-admin/icon-dropdown.svg";

export default {
  name: "MenuButton",
  components: {
    DropdownIcon,
  },
  props: {
    title: String,
    showDropDown: {
      type: Boolean,
      default: true
    },
    disabledDropdown: {
      type: Boolean,
      default: false,
    },
    right: {
      type: String,
    },
    customStyle: String,
    setunlockTemplate:Function,
    disableBtn: {
      type: Boolean,
      default: () => false
    },
    formData: {
      type: Object
    }
  },
  data() {
    return {
      sendoptions: false,
    };
  },
  methods: {
    popupbtn() {
      if (!this.disabledDropdown)
        this.sendoptions = !this.sendoptions;
    },
    async handleSubmit() {
      this.sendoptions = false;
      if(this.formData?.mode == 'view'){
         return
      }
      if (this.setunlockTemplate && this.formData?.mode !== 'view' && !('lock' in this.formData)) {
        this.setunlockTemplate();
      }
      this.$emit("onSave");
    },
    handleBtnClick() {
      if (this.disableBtn) this.popupbtn();
      else  this.handleSubmit();
    
    },
    onOutsideClick() {
      this.sendoptions = false;
    }
  },
};
